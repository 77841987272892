import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["uppyDashboard", "photoInput"]
  static values = {uploadLocally: Boolean}

  async connect() {
    this.uploadLocally = JSON.parse(this.data.get("uploadLocally"))
    const { default: Uppy } = await import("@uppy/core")
    const { default: AwsS3 } = await import("@uppy/aws-s3")
    const { default: XHRUpload } = await import("@uppy/xhr-upload")
    const { default: Dashboard } = await import("@uppy/dashboard")
    const { default: Webcam } = await import("@uppy/webcam")

    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/jpeg", "image/png", "image/webp"]
      }
    })
    .use(Dashboard, {
      inline: true,
      width: "100%",
      target: this.uppyDashboardTarget,
      proudlyDisplayPoweredByUppy: false
    })
    .use(Webcam, {
      target: Dashboard,
      modes: ["picture"]
    })

    if (this.uploadLocallyValue) {
      this.uppy.use(XHRUpload, {
        endpoint: "/tenant_cache/upload"
      })
    } else {
      this.uppy.use(AwsS3, {
        companionUrl: "/tenant_cache/"
      })
    }

    this.uppy.on("upload-success", this.onUploadSuccess)
    this.uppy.on("upload-error", this.onUploadError)
  }

  disconnect() {
    this.uppy?.close()
  }

  onUploadSuccess = (file, response) => {
    const dataForShrine = this.uploadLocallyValue ?
      response.body :
      {
        id: file.meta["key"].match(/^od-[^\/]+\/(.+)/)[1], // Delete the od-*/ prefix
        storage: "tenant_cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      }

    this.photoInputTarget.value = JSON.stringify(dataForShrine)
    this.element.submit()
  }

  onUploadError = () => {
    alert("An unexpected error occurred. Please try again or contact us for support.")
  }
}
