import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
const { disableElement, enableElement } = Rails

export default class extends Controller {
  static targets = ["uppy", "input"]
  static values = {uploadLocally: Boolean}

  async connect() {
    const { default: Uppy } = await import("@uppy/core")
    const { default: AwsS3 } = await import("@uppy/aws-s3")
    const { default: XHRUpload } = await import("@uppy/xhr-upload")
    const { default: Dashboard } = await import("@uppy/dashboard")
    const { default: Webcam } = await import("@uppy/webcam")

    this.submitButton = this.element.closest("form").querySelector('button[type="submit"]')

    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/jpeg", "image/png", "image/webp", ".heic", ".heif", ".pdf"]
      }
    })
    .use(Dashboard, {
      inline: true,
      width: "100%",
      height: 400,
      target: this.uppyTarget,
      proudlyDisplayPoweredByUppy: false,
      doneButtonHandler: null,
      hideProgressAfterFinish: true,
      showRemoveButtonAfterComplete: true
    })
    .use(Webcam, {
      target: Dashboard,
      modes: ["picture"]
    })

    if (this.uploadLocallyValue) {
      this.uppy.use(XHRUpload, {
        endpoint: "/tenant_cache/upload"
      })
    } else {
      this.uppy.use(AwsS3, {
        companionUrl: "/tenant_cache/"
      })
    }

    this.uppy.on("upload", this.onUpload)
    this.uppy.on("upload-success", this.onUploadSuccess)
    this.uppy.on("upload-error", this.onUploadError)
  }

  disconnect() {
    this.uppy?.close()
  }

  onUpload = (data) => {
    disableElement(this.submitButton)
  }

  onUploadSuccess = (file, response) => {
    const dataForShrine = this.uploadLocallyValue ?
      response.body :
      {
        id: file.meta["key"].match(/^od-[^\/]+\/(.+)/)[1], // Delete the od-*/ prefix
        storage: "tenant_cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      }

    enableElement(this.submitButton)
    this.inputTarget.value = JSON.stringify(dataForShrine)
  }

  onUploadError = () => {
    alert("An unexpected error occurred. Please try again or contact us for support.")
  }
}
