import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import 'tippy.js/themes/light-border.css';
import { updateContext } from "util/context";
const classes = require("util/classes")(__filename)

const isVisible = el => el.offsetParent !== null
const isFirstChild = el => el === el.parentElement.firstElementChild

export default class extends Controller {
  static targets = ["variant", "swatch", "quickAdd", "hiddenPriceContent", "hiddenPriceTooltip", "vspHeart"]

  tooltipShowTimeout = null

  connect() {
    this.currentVariantId = this.variantTargets[0].dataset.variantId
    this.showCurrentVariant()

    this.quickAddTargets.forEach(quickAdd => {
      const tooltip = tippy(quickAdd, {
        theme: "light-border",
        placement: "bottom-end",
        hideOnClick: false,
        content: "Add to My Try-on",
        zIndex: 2
      })

      if (this.showQuickAddTooltip(quickAdd)) {
        this.tooltipShowTimeout = setTimeout(() => { tooltip.show() }, 1000)
      }
    })

    this.hiddenPriceContentTargets.forEach(hiddenPriceContent => {
      tippy(hiddenPriceContent, {
        theme: "light-border",
        placement: "top",
        hideOnClick: false,
        content: this.hiddenPriceTooltipTarget.innerHTML,
        allowHTML: true,
        interactive: true,
      })
    })

    if (this.hasVspHeartTarget && isFirstChild(this.element)) {
      this.vspHeartTarget.classList.add(classes("animate"))
    }
  }

  disconnect() {
    if (this.tooltipShowTimeout) {
      clearTimeout(this.tooltipShowTimeout)
    }
  }

  onSwatchClick(event) {
    const { variantId } = event.currentTarget.dataset;
    this.currentVariantId = variantId;
    this.showCurrentVariant()
  }

  showCurrentVariant() {
    this.toggleCurrent(this.variantTargets)
    this.toggleCurrent(this.swatchTargets)
    this.toggleCurrent(this.quickAddTargets)
  }

  toggleCurrent(els) {
    els.forEach(el => {
      el.classList.toggle(classes("current"), el.dataset.variantId === this.currentVariantId)
    });
  }

  onQuickAddClick() {
    updateContext(this, "restoreFocusTo", this.quickAddTarget)
    mixpanel.track("Quick Add to Try-on")
  }

  showQuickAddTooltip(quickAdd) {
    if (isFirstChild(this.element) && isVisible(quickAdd)) {
      return true
    }
  }
}
