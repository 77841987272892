import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["form", "locationId", "selectLocation", "nearestButton", "nearestLabel", "nearestLoading"]

  nearestLocation(event) {
    event.preventDefault()

    this.nearestButtonTarget.style.width = `${this.nearestButtonTarget.offsetWidth}px`
    this.nearestLabelTarget.classList.add(classes("hide"))
    this.nearestLoadingTarget.classList.remove(classes("hide"))

    navigator.geolocation.getCurrentPosition(this.onGeoSuccess, this.onGeoError)
  }

  onGeoSuccess = async (position) => {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;

    const response = await fetch(`/api/locations/nearest?latitude=${latitude}&longitude=${longitude}`)

    if (response.status === 200) {
      const locations = await response.json()

      if (locations.length > 0) {
        const location = locations[0]
        this.locationIdTarget.value = location.id
        this.formTarget.requestSubmit()
      } else {
        this.navigateToSelectLocation()
      }
    } else {
      this.navigateToSelectLocation()
    }
  }

  onGeoError = () => this.navigateToSelectLocation()

  navigateToSelectLocation() {
    const url = new URL(this.selectLocationTarget.href)
    url.searchParams.append("show_geo_error", "true")
    this.selectLocationTarget.href = url.toString()
    this.selectLocationTarget.click()
  }
}
