import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pdField", "form"]
  hasResult = false

  connect() {
    mixpanel.track("Virtual Fit - Started")

    // Note that the Fittingbox widget only works if the container's ID is msrt-container
    Msrt.createWidget("msrt-container", {
      apiKey: "XrE8IFPsqxtP8Pfc0OFSwVSQ6Ykmb4vHegHXs0nG",
      widgetUrl: "https://pd-measurement.fittingbox.com",
      onGetResultPd: (result) => {
        this.hasResult = true
        this.pdFieldTarget.value = result.pd
      },
      onClose: () => {
        if (this.hasResult) {
          this.formTarget.requestSubmit()
        } else {
          mixpanel.track("Virtual Fit - Dismissed")
          const event = new Event("close-modal")
          this.element.dispatchEvent(event)
        }
      }
    })
  }
}
