const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)
const pieces = (name) => name.split(/(?:(?=[A-Z])|[-_])/)
const dasherize = (name) => pieces(name).join("-").toLowerCase()
const camelize = (name) => pieces(name).map(capitalize).join("")

const dataAttr = (name) => `data-context-${dasherize(name)}`
const dataKey = (name) => `context${camelize(name)}`

const findContextEl = (childController, name) => childController.element.closest(`[${dataAttr(name)}]`)

export function createContext(controller, name, value) {
  controller.element.setAttribute(dataAttr(name), "")
  return controller.element[dataKey(name)] = value
}

export function useContext(childController, name) {
  return findContextEl(childController, name)[dataKey(name)]
}

export function updateContext(childController, name, value) {
  return findContextEl(childController, name)[dataKey(name)] = value
}
