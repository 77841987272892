import { Controller } from "@hotwired/stimulus"
import { ModalScrollFix } from "util/modal";
import { ModalFocus } from "util/focus";
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["modal", "content"]
  static values = {show: Boolean, dismissable: Boolean}

  connect() {
    this.scrollFix = new ModalScrollFix()
    this.focus = new ModalFocus()
    if (this.showValue) this.show()
  }

  disconnect() {
    this.hide()
  }

  onCloseClick() {
    this.hide()
  }

  onOverlayClick(event) {
    if (!this.dismissableValue) return
    if (this.modalTarget.contains(event.target)) return

    this.hide()
  }

  onBodyKeyup(event) {
    if (!this.dismissableValue) return
    if (event.key !== "Escape") return
    this.hide()
  }

  hide() {
    if (!this.showValue) return
    this.showValue = false
    this.scrollFix.unfix()
    this.element.classList.remove(classes("show"))
    this.focus.restore()
  }

  show() {
    this.showValue = true
    this.scrollFix.fix()
    this.element.classList.add(classes("show"))
    this.focus.moveInto(this.contentTarget)
  }
}
