import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"
import { isMobileOnly } from "util/media"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["fixed"]

  isFixed = false

  connect() {
    this.reset()
  }

  reset() {
    const rect = this.element.getBoundingClientRect()
    this.fullHeight = rect.height
    this.yOffset = Math.abs(Math.round(rect.top + window.scrollY))

    const minDivisor = isMobileOnly() ? 3 : 2
    this.minHeight = this.fullHeight / minDivisor

    this.render()
  }

  render() {
    // Ignore scroll event fired when modal is opening or closing (see ModalComponent)
    if (document.body.classList.contains("global-modal-open")) return

    this.isFixed = window.scrollY > this.yOffset
    this.fixedTarget.classList.toggle(classes("is-fixed"), this.isFixed)
    this.fixedTarget.classList.toggle("global-is-fixed", this.isFixed)

    let scrollPastFixedTarget = window.scrollY - this.yOffset
    if (scrollPastFixedTarget < 0) scrollPastFixedTarget = 0

    let height = this.fullHeight - scrollPastFixedTarget
    if (height < this.minHeight) height = this.minHeight
    this.fixedTarget.style.height = `${height}px`
  }
}
