export function moveFocusToElement(element) {
  const focusableElement = element.querySelector("a[href], area[href], input:not([disabled]):not([type='hidden']), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not([tabindex='-1']), [tabindex='0']")
  focusableElement?.focus()
}

export class ModalFocus {
  restoreFocusTo = null

  restore() {
    if (!this.restoreFocusTo) return
    this.restoreFocusTo.focus()
    this.restoreFocusTo = null
  }

  moveInto(element) {
    this.restoreFocusTo = document.activeElement
    moveFocusToElement(element)
  }
}
