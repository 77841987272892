function scopedName(name, base) {
  return base
    .replace(/(_component|_controller)$/, "")
    .replace(/\//g, "--")
    .replace(/([^\w]|_)/g, "-") + "--" + name;
}

// Use CommonJS/Node-style exports so this can be imported by postcss.config.js
module.exports = function generateScopedName(name, filename) {
  const componentMatch = filename.match(/app\/components\/(.*)\.(s?css|js)$/i)
  const viewMatch = filename.match(/app\/views\/(.*)\.s?css$/i)

  if (componentMatch) {
    return scopedName(name, componentMatch[1])
  } else if (viewMatch) {
    return scopedName(name, `views/${viewMatch[1]}`)
  } else {
    return name
  }
}
