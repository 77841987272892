import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

const hiddenKey = "promo_popup_hidden"

export default class extends Controller {
  connect() {
    if (!localStorage.getItem(hiddenKey)) this.show()
  }

  onCloseClick() {
    this.hide()
  }

  hide() {
    localStorage.setItem(hiddenKey, true)
    this.element.classList.remove(classes("show"))
  }

  show() {
    this.element.classList.add(classes("show"))
  }
}
