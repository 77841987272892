import { Controller } from "@hotwired/stimulus"
import { addPositionStickyEvents } from "util/sticky_events";
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["ageOption", "genderOption", "featuredFilters", "featuredFiltersLeft", "brands"]
  static values = { featuredFiltersLeft: Number }

  mobileFiltersOpen = false

  connect() {
    this.observer = addPositionStickyEvents(this.element)
    this.featuredFiltersTarget.scrollLeft = this.featuredFiltersLeftValue
  }

  disconnect() {
    this.observer.disconnect()
  }

  onStickyChange(event) {
    this.element.classList.toggle(classes("is-sticky"), event.detail.stuck)
  }

  clearCheckboxesFilter(event) {
    event.target.closest(`.${classes("filter")}`).querySelectorAll("input[type=checkbox]").forEach(el => el.disabled = true)
  }

  submit(event) {
    event.target.closest("form").requestSubmit()
  }

  onSubmit() {
    this.featuredFiltersLeftTarget.value = this.featuredFiltersTarget.scrollLeft
  }
}
