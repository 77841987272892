import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["opener", "content"]
  static values = {open: Boolean}

  connect() {
    this.render()
  }

  open() {
    this.openValue = true
    this.render()
  }

  close() {
    this.openValue = false
    this.render()
  }

  render() {
    this.openerTarget.classList.toggle(classes("hide"), this.openValue)
    this.contentTarget.classList.toggle(classes("hide"), !this.openValue)
    this.element.querySelectorAll("input, select").forEach(el => el.disabled = !this.openValue)
  }
}
