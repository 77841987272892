// Require the polyfill before requiring any other modules.
import "intersection-observer"
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
require.context('../images', true)
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails"

import Rails from "@rails/ujs"
Rails.start()

// Make Rails UJS disable-with work nicely with Turbo https://github.com/hotwired/turbo/pull/40
const { delegate, disableElement, enableElement } = Rails
delegate(document, Rails.linkDisableSelector, "turbo:before-cache", enableElement)
delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", enableElement)
delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", enableElement)
delegate(document, Rails.formSubmitSelector, "turbo:submit-start", disableElement)
delegate(document, Rails.formSubmitSelector, "turbo:submit-end", enableElement)
delegate(document, Rails.formSubmitSelector, "turbo:before-cache", enableElement)

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import Appsignal from "@appsignal/javascript"
import { installErrorHandler } from "@appsignal/stimulus"


const application = Application.start()

if (APPSIGNAL_FRONTEND_KEY) {
  const appsignal = new Appsignal({
    key: APPSIGNAL_FRONTEND_KEY,
    revision: SOURCE_VERSION
  })
  installErrorHandler(appsignal, application)
}

// Load all the StimulusJS controllers in ./controllers/shopper/**/*, ./controllers/shared/**/*, and app/components/shopper/**/*
// Controller files must be named *_controller.ts
const shopperControllers = require.context("../controllers", true, /shopper.*controller\.[jt]s$/)
const sharedControllers = require.context("../controllers", true, /shared.*controller\.[jt]s$/)
const shopperComponents = require.context("../../components", true, /shopper.*controller\.[jt]s$/)
application.load(
  definitionsFromContext(shopperControllers)
    .concat(definitionsFromContext(sharedControllers))
    .concat(definitionsFromContext(shopperComponents))
)

// Load global stylesheet
import "../stylesheets/shopper/global.scss"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "@uppy/webcam/dist/style.css"

// Load all the CSS Module stylesheets in app/components/**/* and app/views/**/*
require.context("../../components", true, /shopper.*.s?css$/)
require.context("../../views", true, /shopper.*.s?css$/)
require.context("../../views", true, /errors.*.s?css$/)
