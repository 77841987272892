import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
const { disableElement, enableElement } = Rails
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["address", "creditCard", "creditCardToken", "submit"]
  static values = {collectCreditCard: Boolean, stripeKey: String, odStripeAccount: String}

  connect() {
    if (this.collectCreditCardValue) {
      this.stripe = Stripe(this.stripeKeyValue, { stripeAccount: this.odStripeAccountValue})
      this.card = this.stripe.elements().create("card", {
        style: {
          base: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "16px",
          }
        }
      })
      this.card.mount(this.creditCardTarget)
    }
  }

  selectShip() {
    this.addressTarget.classList.remove(classes("hidden"))
  }

  selectPickup() {
    this.addressTarget.classList.add(classes("hidden"))
  }

  async submit(event) {
    disableElement(this.submitTarget)

    if (this.collectCreditCardValue) {
      event.preventDefault()

      const result = await this.stripe.createToken(this.card)

      if (result.error) {
        enableElement(this.submitTarget)
      } else {
        const token = result.token.id
        this.creditCardTokenTarget.value = token
        event.target.submit()
      }
    }
  }
}
