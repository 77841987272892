export function fireStickyEvent(stuck, target, bubbles=false) {
  const e = new CustomEvent('sticky-change', { bubbles: bubbles, detail: { stuck } })
  target.dispatchEvent(e)
}

function parseComputedStyle(value) {
  if (/px$/.test(value)) {
    return parseInt(value)
  } else {
    console.warn("only pixel offsets are supported")
    return 0
  }
}

function determineEdge(element) {
  const style = window.getComputedStyle(element)

  if (style.top != "auto") {
    return { rootMargin: { top: parseComputedStyle(style.top) * -1, bottom: 0 }, edge: "top" }
  } else if (style.bottom != "auto") {
    const bottom = parseComputedStyle(style.bottom) + element.offsetHeight
    return { rootMargin: { top: 0, bottom: bottom * -1 }, edge: "bottom" }
  } else {
    console.warn("failed to determine edge")
    return { rootMargin: { top: 0, bottom: 0 }, edge: "top" }
  }
}

export function addPositionStickyEvents(stickyEl) {
  const { rootMargin, edge } = determineEdge(stickyEl)

  const observer = new IntersectionObserver(records => {
    for (const record of records) {
      const targetInfo = record.boundingClientRect
      const rootBoundsInfo = record.rootBounds

      if (edge == "top") {
        if (targetInfo.bottom < rootBoundsInfo.top)
          fireStickyEvent(true, stickyEl)
        else if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom)
          fireStickyEvent(false, stickyEl)
      } else if (edge == "bottom") {
        if (targetInfo.bottom > rootBoundsInfo.bottom)
          fireStickyEvent(true, stickyEl)
        else if (targetInfo.bottom <= rootBoundsInfo.bottom)
          fireStickyEvent(false, stickyEl)
      }
    }
  }, { threshold: 0, root: null, rootMargin: `${rootMargin.top}px 0px ${rootMargin.bottom}px 0px` })

  const sentinel = document.createElement('div')
  stickyEl.parentElement.insertBefore(sentinel, stickyEl)
  observer.observe(sentinel)

  return observer;
}
