import { Controller } from "@hotwired/stimulus"
import { isTabletOnly, isMobileOnly, isTabletUp } from "util/media"
import { fireStickyEvent } from "util/sticky_events"
import { moveFocusToElement } from "util/focus"
import { useContext, updateContext } from "util/context"
const classes = require("util/classes")(__filename)
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ["opener", "content"]
  static values = {mobile: String, open: Boolean, offsetY: Number, flip: Boolean}
  isOpen = false

  connect() {
    this.contentTargetOriginalWidth = this.contentTarget.style.width

    const topLogger = {
      name: "topLogger",
      enabled: true,
      phase: "afterWrite",
      fn: ({ state }) => {
        if (this.isOpen) {
          if (state.placement.startsWith("top-")) {
            this.recalculateMaxHeight("top")
          } else if (state.placement.startsWith("bottom-")) {
            this.recalculateMaxHeight("bottom")
          }

          if (this.mobileValue === "overshadow") this.goFullWidthMobile()

          this.element.dispatchEvent(new Event("dropdown-opened"))
          moveFocusToElement(this.contentTarget)
        }
      },
    };

    this.popper = createPopper(this.openerTarget, this.contentTarget, {
      placement: "bottom-end",
      strategy: "fixed",
      modifiers: [
        { name: "offset", options: { offset: [0, this.offsetYValue] } },
        { name: "flip", enabled: this.flipValue },
        topLogger
      ]
    })

    if (this.openValue) {
      this.open()
    }
  }

  onClick(event) {
    event.preventDefault()
    this.isOpen ? this.close() : this.open()
  }

  onBodyClick(event) {
    if (!this.isOpen) return
    if (this.element.contains(event.target)) return

    this.close()
  }

  open() {
    this.isOpen = true
    this.element.classList.add(classes("open"))
    this.contentTarget.classList.remove(classes("hide"))
    this.popper.update()
  }

  close() {
    this.isOpen = false
    this.element.classList.remove(classes("open"))
    this.contentTarget.classList.add(classes("hide"))
    this.element.dispatchEvent(new Event("dropdown-closed"))
    this.restoreOriginalFocus()
  }

  recalculateMaxHeight(popperPlacement) {
    if (!this.isOpen) return

    if (popperPlacement == "top") {
      this.contentTarget.style.maxHeight = `${window.innerHeight - (window.innerHeight - this.contentTarget.getBoundingClientRect().bottom)}px`
    } else if (popperPlacement == "bottom") {
      this.contentTarget.style.maxHeight = `${window.innerHeight - this.contentTarget.getBoundingClientRect().top}px`
    }
  }

  goFullWidthMobile() {
    if (isTabletUp()) {
      this.contentTarget.style.width = this.contentTargetOriginalWidth;
    } else {
      this.contentTarget.style.width = "100vw"
      if(this.isOpen) fireStickyEvent(true, this.element, true)
    }
  }

  restoreOriginalFocus() {
    const restoreFocusTo = useContext(this, "restoreFocusTo")
    if (restoreFocusTo) {
      restoreFocusTo.focus()
      updateContext(this, "restoreFocusTo", null)
    }
  }
}
