import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["listContainer", "loadingSpinner", "scrollToTop"]
  static values = {
    defaultPerPage: Number,
    initialPerPage: Number,
    totalProductsCount: Number,
    loadedProductsCount: Number
  }

  fetching = false

  connect() {
    // This assumes initialPerPage is a multiple of defaultPerPage, which will always be the
    // case when the code below paginates
    this.page = this.initialPerPageValue / this.defaultPerPageValue
    this.toggleLoadingSpinners()
  }

  onScroll() {
    const nearBottomScrollPosition = document.body.scrollHeight - (window.innerHeight * 1.5)

    if (window.scrollY >= nearBottomScrollPosition / this.page) this.showScrollToTop()
    else this.hideScrollToTop()

    if (this.fetching || !this.pagesRemaining()) return
    if (window.scrollY >= nearBottomScrollPosition) this.fetchMore()
  }

  scrollToTop() {
    this.hideScrollToTop()
    window.scrollTo(0, 0)
  }

  showScrollToTop() {
    this.scrollToTopTarget.classList.remove(classes("hidden"))
  }
  
  hideScrollToTop() {
    this.scrollToTopTarget.classList.add(classes("hidden"))
  }
  
  fetchMore() {
    this.page++
    this.handlePagination()
  }

  handlePagination() {
    this.fetching = true

    const url = new URL(window.location.href)
    const query = url.searchParams
    query.set("page", this.page)
    query.delete("per_page")
    url.search = query.toString()

    Rails.ajax({
      type: "get",
      url: url,
      success: (response, status, xhr) => {
        const html = xhr.responseText

        if (/^\s*$/.test(html)) {
          this.noMorePagesToLoad()
        } else {
          this.loadedProductsCountValue += this.defaultPerPageValue
          this.listContainerTarget.insertAdjacentHTML('beforeend', html)
          this.handleHistory()
        }

        this.toggleLoadingSpinners()
        this.fetching = false
      }
    })
  }

  noMorePagesToLoad() {
    this.loadedProductsCountValue = this.totalProductsCountValue
  }

  handleHistory() {
    const perPage = this.loadedProductsCountValue
    const url = new URL(window.location.href)
    const query = url.searchParams
    if (perPage > Number(query.get("per_page")))
      query.set("per_page", perPage)
    url.search = query.toString()
    history.replaceState(history.state, '', url)
  }

  pagesRemaining() {
    return this.loadedProductsCountValue < this.totalProductsCountValue
  }

  toggleLoadingSpinners() {
    if (this.pagesRemaining()) {
      this.loadingSpinnerTargets.forEach(this.showLoaderAtBottom.bind(this))
    } else {
      this.loadingSpinnerTargets.forEach(el => el.remove())
    }
  }

  showLoaderAtBottom(loaderTarget) {
    this.listContainerTarget.appendChild(loaderTarget)
    loaderTarget.classList.remove(classes("hidden"))
  }
}
