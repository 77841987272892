import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["virtualTryOn"]

  delegateVirtualTryOnEvent(event) {
    const delegateEvent = new CustomEvent("virtual-try-on-change", { detail: event.detail })
    this.virtualTryOnTarget.dispatchEvent(delegateEvent)
  }
}
