import { Controller } from "@hotwired/stimulus"
import { default as tippy } from "tippy.js"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light-border.css"

export default class extends Controller {
  static values = { label: String, placement: String, showOnCreate: Boolean }

  connect() {
    tippy(this.element, {
      theme: "light-border",
      placement: this.placementValue,
      showOnCreate: this.showOnCreateValue,
      trigger: "mouseenter click",
      content: this.labelValue
    })
  }
}
