import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)
import { addPositionStickyEvents } from "util/sticky_events";

export default class extends Controller {
  static targets = ["filterTab", "sortTab", "filters", "sorts", "actions"]

  connect() {
    this.observer = addPositionStickyEvents(this.actionsTarget)
  }

  disconnect() {
    this.observer.disconnect()
  }

  goFilters() {
    this.sortsTarget.classList.add(classes("hide"))
    this.sortTabTarget.classList.remove(classes("active"))

    this.filtersTarget.classList.remove(classes("hide"))
    this.filterTabTarget.classList.add(classes("active"))
  }

  goSorts() {
    this.sortsTarget.classList.remove(classes("hide"))
    this.sortTabTarget.classList.add(classes("active"))

    this.filtersTarget.classList.add(classes("hide"))
    this.filterTabTarget.classList.remove(classes("active"))
  }

  onActionsStickyChange(event) {
    this.actionsTarget.classList.toggle(classes("is-sticky"), event.detail.stuck)
  }
}
