export function mobileModalScrollFix() {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.left = '0px'
  document.body.style.right = '0px'
  document.body.style.position = 'fixed';
}

export function mobileModalScrollUnfix() {
  const scrollY = document.body.style.top
  document.body.style.position = ''
  document.body.style.top = ''
  document.body.style.left = ''
  document.body.style.right = ''
  window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

export function isVerticalScrollbarDisplayed() {
  return window.innerWidth > document.documentElement.clientWidth
}

export function getVerticalScrollbarSize() {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#usage_notes
  const documentWidth = document.documentElement.clientWidth
  return Math.abs(window.innerWidth - documentWidth)
}

export function getPaddingRight(element) {
  return parseInt(getComputedStyle(element).paddingRight, 10) || 0
}

export function buildRestorePaddingForElement(element) {
  return { element: element, paddingRight: element.style.paddingRight }
}

export class ModalScrollFix {
  fixed = false
  restorePaddingElements = []

  fix() {
    if (this.fixed) return
    this.fixed = true

    document.body.classList.add("global-modal-open")

    if (isVerticalScrollbarDisplayed()) {
      const scrollbarSize = getVerticalScrollbarSize()

      const elementsToPad = [document.body, ...document.querySelectorAll(".global-is-fixed")]
      elementsToPad.forEach(element => {
        this.restorePaddingElements.push(buildRestorePaddingForElement(element))
        element.style.paddingRight = `${getPaddingRight(element) + scrollbarSize}px`
      })
    } else {
      this.restorePaddingElements = []
    }

    mobileModalScrollFix()
  }

  unfix() {
    if (!this.fixed) return
    this.fixed = false

    document.body.classList.remove("global-modal-open")

    this.restorePaddingElements.forEach(item => item.element.style.paddingRight = item.paddingRight)
    this.restorePaddingElements = []
    mobileModalScrollUnfix()
  }
}
