import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["digit", "hiddenInput"]
  static values = {maxLength: Number}

  connect() {
    this.enableForm()
    this.digitTarget.focus()
  }

  selectAllText(event) {
    event.target.select()
  }

  pasteCode(event) {
    const clipboardText = event.clipboardData.getData("text").trim()
    if (clipboardText.length > 1) {
      event.preventDefault()
      this.digitTargets.forEach((digitInput, index) => digitInput.value = clipboardText[index] || digitInput.value)
      this.digitTargets.reverse().find((digitInput) => !digitInput.value)?.select()
      this.updateCode()
    } else if (!clipboardText.length) {
      event.preventDefault()
    }
  }

  changeFocus(event) {
    const input = event.target
    if (input.value) {
      this.focusAdjacentDigit(input, "next")
      this.updateCode()
    }
  }

  focusAdjacentDigit(currentDigit, newPosition) {
    const currentDigitIndex = Number(currentDigit.dataset["digitIndex"])
    const adjacentIndex = newPosition == "next" ? currentDigitIndex + 1 : currentDigitIndex - 1
    this.digitTargets[adjacentIndex]?.focus()
  }

  updateCode() {
    this.codeValue = this.digitTargets.map(digit => digit.value).join("")
    if (this.codeValue.length == this.maxLengthValue) {
      this.hiddenInputTarget.value = this.codeValue
      this.element.closest("form").requestSubmit()
      this.disableForm()
    }
  }

  disableForm() {
    this.element.classList.add(classes("loading"))
    this.digitTargets.forEach(digit => digit.disabled = true)
  }

  enableForm() {
    this.element.classList.remove(classes("loading"))
    this.digitTargets.forEach(digit => digit.disabled = false)
  }

  changeFocusWithKeyboard(event) {
    const input = event.target
    if (event.key == "ArrowLeft") {
      event.preventDefault()
      this.focusAdjacentDigit(input, "previous")
    } else if (event.key == "ArrowRight" || event.key == " ") {
      event.preventDefault()
      this.focusAdjacentDigit(input, "next")
    } else if (event.key == "Backspace" && !input.value) {
      this.focusAdjacentDigit(input, "previous")
    }
  }
}
