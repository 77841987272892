import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = [
    "visionPlanSelect",
    "usingVisionPlanFields",
    "primaryMemberRadio",
    "primaryMemberFields",
    "dependentMemberRadio",
    "dependentMemberFields"
  ]

  connect() {
    this.renderVisionPlan()
  }

  renderVisionPlan() {
    const usingVisionPlan = !!this.visionPlanSelectTarget.value
    const isPrimaryMember = this.primaryMemberRadioTarget.checked
    const isDependentMember = this.dependentMemberRadioTarget.checked

    if (usingVisionPlan) {
      this.usingVisionPlanFieldsTarget.classList.remove(classes("hide"))
    } else {
      this.usingVisionPlanFieldsTarget.classList.add(classes("hide"))
    }

    this.toggleFields(this.primaryMemberFieldsTarget, isPrimaryMember)
    this.toggleFields(this.dependentMemberFieldsTarget, isDependentMember)
  }

  toggleFields(container, enabled) {
    container.classList.toggle(classes("hide"), !enabled)
    container.querySelectorAll("input").forEach(el => el.disabled = !enabled)
  }
}
