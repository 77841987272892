import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

function toToken(string) {
  return string.replace(/[^\w]/g, "").toLowerCase()
}

export default class extends Controller {
  static targets = ["option"]

  options = []

  connect() {
    this.options = this.optionTargets.map(o => [toToken(o.textContent), o])
  }

  onSearch(event) {
    const needle = toToken(event.target.value)

    this.options.forEach(option => {
      if (option[0].includes(needle)) {
        option[1].classList.remove(classes("hide"))
      } else {
        option[1].classList.add(classes("hide"))
      }
    })
  }
}
