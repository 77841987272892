import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { displayedFittingboxId: String }

  connect() {
    // Run later to prevent dispatching the event before all SJS controllers have connected
    setTimeout(() => {
      if (this.displayedFittingboxIdValue) {
        const detail = { fittingboxId: this.displayedFittingboxIdValue }
        const event = new CustomEvent("virtual-try-on-change", { bubbles: true, detail: detail })
        this.element.dispatchEvent(event)
      }
    })
  }

  onSwatchClick(event) {
    const base = `${window.location.protocol}//${window.location.host}`
    const url = new URL(event.params.vanityUrl, base)
    Turbo.session.history.replace(url)
  }
}
