import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static values = {fittingboxKey: String, variantId: Number}
  fitmixInstance = null

  show(event) {
    event.preventDefault()

    const fittingboxId = event.detail.fittingboxId

    if (this.fitmixInstance) {
      this.fitmixInstance.setFrame(fittingboxId)
    } else {
      this.fitmixInstance = FitMix.createWidget(
        "fitmix-container",
        { apiKey: this.fittingboxKeyValue },
        () => {
          this.fitmixInstance.setFrame(fittingboxId)
          this.fitmixInstance.setLensesAntireflection(true)
          this.fitmixInstance.startVto("live")
        }
      )
    }

    this.element.classList.remove(classes("hide"))

    mixpanel.track("Virtual Try-on", { variant_id: this.variantIdValue })
  }
}
