export function getBreakpoint() {
  return window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
}

export function isMobileOnly() {
  return getBreakpoint() == "mobile-only"
}

export function isTabletUp() {
  return getBreakpoint() == "tablet-up" || isDesktopUp()
}

export function isTabletOnly() {
  return getBreakpoint() == "tablet-up"
}

export function isDesktopUp() {
  return getBreakpoint() == "desktop-up"
}
