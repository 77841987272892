import 'lazysizes';
import { Controller } from "@hotwired/stimulus"
import { createContext } from "util/context";

export default class extends Controller {
  connect() {
    createContext(this, "restoreFocusTo", null)
  }

  onKeydown(event) {
    if (event.key === "Tab") this.enableKeyboardNavigation()
  }

  enableKeyboardNavigation() {
    this.element.classList.add("keyboard-navigation-active")
  }
}
