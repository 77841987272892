import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["collapsed", "expanded"]

  otherSelected(event) {
    event.preventDefault()
    this.collapsedTarget.classList.add(classes("hide"))
    this.expandedTarget.classList.remove(classes("hide"))
  }
}
