import { Controller } from "@hotwired/stimulus"
const classes = require("util/classes")(__filename)

export default class extends Controller {
  static targets = ["content"]

  toggleContentDisplay(event) {
    event.preventDefault()
    this.contentTarget.classList.toggle(classes("hidden"))
  }
}
